.product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .upload {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
} */

.upload img{
    height: 100px;
    display: block;
    margin-bottom: 5px;
}

